import "./EnrollmentManager.css";

import {
  Box,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { buildEnrollmentRow, getGuid } from "../../helpers/utils";

import Container from "../custom/container/Container";
import DownloadExport from "../custom/download-export/DownloadExport";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getBatches } from "../../api/batch";
import { getCourses } from "../../api/course";
import { getEnrollments } from "../../api/enroll";
import { getProfiles } from "../../api/user";
import { getSessions } from "../../api/session";
import { getTeachers } from "../../api/teacher";

function EnrollmentManager() {
  const [enrollments, setEnrollments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtering, setFiltering] = useState(false);
  const [selectedSession, setSelectedSession] = useState("All");
  const [sessionOptions, setSessionOptions] = useState([]);
  

  // !NOTE pagination related state
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectedSessionChange = (e) => {
    setSelectedSession(e.target.value);
  }

  useEffect(async() => {
    setFiltering(true);
    try {
      // get all the enrollments
      const enrollmentList = await getEnrollments();

      // get all the teachers
      const teacherList = await getTeachers();

      // get all the batches
      const batchList = await getBatches();

      // get all the sessions
      const sessionList = await getSessions();
      setSessionOptions([...sessionList, {id: 'All'}]);

      // get all the courses
      const courseList = await getCourses();

      // get all the profiles
      const userProfileList = await getProfiles();

      // link all data together and display
      const enrollmentsCombined = enrollmentList.map(
        ({
          id: enrollmentId,
          data: {
            course: courseId,
            batch: batchId,
            uid: studentId,
            materialDeliveryPref,
            timestamp,
          },
        }) => {
          const course = courseList.find((c) => c.id === courseId);
          const batch = batchList.find((b) => b.id === batchId);
          const session = batch
            ? sessionList.find((s) => s.id === batch.data.session)
            : null;
          const primaryTeacher =
            batch && batch.data.primaryTeacher
              ? teacherList.find((t) => t.id === batch.data.primaryTeacher)
              : null;
          const secondaryTeacher =
            batch && batch.data.secondaryTeacher
              ? teacherList.find((t) => t.id === batch.data.secondaryTeacher)
              : null;
          const student = studentId
            ? userProfileList.find((u) => u.data.uid === studentId)
            : null;

          if(session && selectedSession && (session.id !== selectedSession) && selectedSession !== 'All'){
            return null;
          }

          if(!session && selectedSession !== 'All') {
            return null;
          }

          return buildEnrollmentRow(enrollmentId, course, courseId, session, batch, batchId, primaryTeacher, secondaryTeacher, student, materialDeliveryPref, timestamp); 
        }
      );

      let filteredEnrollment = enrollmentsCombined.filter((en) => en);
      setEnrollments(filteredEnrollment);
      console.log("Filtered Enrollments = ", filteredEnrollment);
    } catch (err) {
      console.log("Encountered error while initiliazing enrollments ", err);
    }
    setFiltering(false);
  }, [selectedSession])

  const initialize = useCallback(async () => {
    setLoading(true);
    try {
      // get all the enrollments
      const enrollmentList = await getEnrollments();

      // get all the teachers
      const teacherList = await getTeachers();

      // get all the batches
      const batchList = await getBatches();

      // get all the sessions
      const sessionList = await getSessions();
      setSessionOptions([...sessionList, {id: 'All'}]);

      // get all the courses
      const courseList = await getCourses();

      // get all the profiles
      const userProfileList = await getProfiles();

      // link all data together and display
      const enrollmentsCombined = enrollmentList.map(
        ({
          id: enrollmentId,
          data: {
            course: courseId,
            batch: batchId,
            uid: studentId,
            materialDeliveryPref,
            timestamp,
          },
        }) => {
          const course = courseList.find((c) => c.id === courseId);
          const batch = batchList.find((b) => b.id === batchId);
          const session = batch
            ? sessionList.find((s) => s.id === batch.data.session)
            : null;
          const primaryTeacher =
            batch && batch.data.primaryTeacher
              ? teacherList.find((t) => t.id === batch.data.primaryTeacher)
              : null;
          const secondaryTeacher =
            batch && batch.data.secondaryTeacher
              ? teacherList.find((t) => t.id === batch.data.secondaryTeacher)
              : null;
          const student = studentId
            ? userProfileList.find((u) => u.data.uid === studentId)
            : null;

          return buildEnrollmentRow(enrollmentId, course, courseId, session, batch, batchId, primaryTeacher, secondaryTeacher, student, materialDeliveryPref, timestamp); 
        }
      );

      setEnrollments(enrollmentsCombined);
      console.log("Total Enrollments = ", enrollmentsCombined.length);
      console.log("Enrollments = ", enrollmentsCombined);
    } catch (err) {
      console.log("Encountered error while initiliazing enrollments ", err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Container
      heading="Enrollments"
      basic={true}
      helperText={
        enrollments.length > 0 && !filtering
          ? `Found (${enrollments.length}) enrollments 🎉`
          : filtering ? '🔎 Fetching Results...' : `Sorry! no enrollments found 🙏`
      }
      customHeaderButton={
        enrollments.length > 0 && (
          <DownloadExport
            data={enrollments}
            type="enrollment"
            fileName="enrollments"
          />
        )
      }
      loading={loading}
    >
      {!loading && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "end" },
              position: {md: "relative"},
              bottom: { md: "50px"},
              left: { md: "10px"},
              marginTop: {xs: "15px"}
            }}
          >
            <FormControl
              sx={{ m: 1, minWidth: 120, maxWidth: 200 }}
              size="small"
            >
              <InputLabel id="demo-select-small">Session</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={selectedSession}
                label="Session"
                onChange={handleSelectedSessionChange}
              >
                {sessionOptions.map(({ id, data }) => {
                  if (id === "All") {
                    return (
                      <MenuItem key={getGuid()} value={id}>
                        All
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem key={getGuid()} value={id}>
                        {data.startMonth} {data.startYear}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </Box>
          {filtering && <Box sx={{display: "flex", gap: "5px"}}><CircularProgress size={20} /> Filtering...</Box>}
          {!filtering && (
            <>
              <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {enrollments[0]?.map((col) => {
                        if (col?.hidden === undefined) {
                          return (
                            <TableCell
                              key={getGuid()}
                              align={col.align}
                              style={{ minWidth: col.minWidth }}
                            >
                              <b>{col.label}</b>
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {enrollments
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((enrollment) => {
                        return (
                          <TableRow
                            key={getGuid()}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {enrollment.map((cell) => {
                              if (cell?.hidden === undefined) {
                                return (
                                  <TableCell key={getGuid()} align={cell.align}>
                                    {cell.value ? (
                                      cell.value
                                        .toLowerCase()
                                        .indexOf("deleted") !== -1 ? (
                                        <Chip
                                          label={cell.value}
                                          size="small"
                                          color="error"
                                        />
                                      ) : (
                                        cell.value
                                      )
                                    ) : (
                                      <Chip
                                        label="NA"
                                        size="small"
                                        color="warning"
                                      />
                                    )}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {enrollments.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 100]}
                  component="div"
                  count={enrollments.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default EnrollmentManager;
