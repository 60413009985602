export const PATH = {
    COURSES: '/courses',
    SESSIONS: '/sessions',
    TEACHERS: '/teachers',
    BATCHES: '/batches',
    ENROLLMENTS: '/enrollments',
    PROFILE: '/profile',
    ADD_COURSE: '/course/add',
    EDIT_COURSE: '/course/edit/:id',
    ADD_SESSION: '/session/add',
    EDIT_SESSION: '/session/edit/:id',
    ADD_BATCH: '/batch/add',
    EDIT_BATCH: '/batch/edit/:id',
    EDIT_BATCHES: '/batches/edit/:id',
    ADD_TEACHER: '/teacher/add',
    EDIT_TEACHER: '/teacher/edit/:id',
    PROFILE_STATUS: '/profile/:status',
    ADD_ENROLLMENT: '/enrollment/add',
    NEW_ENROLLMENT: '/enrollment/new',
    HELP_AND_SUPPORT: '/help',
    DONATE_US: '/donate'
};

export default PATH
