export function getGuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
export function stringToColor(value) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < value.length; i += 1) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}


export function buildEnrollmentRow(enrollmentId, course, courseId, session, batch, batchId, primaryTeacher, secondaryTeacher, student, materialDeliveryPref, timestamp) {
  return [
    {
      label: "Enrollment Id",
      value: enrollmentId,
      align: "left",
      minWidth: 170,
      hidden: true,
    },
    {
      label: "Course Id",
      value: course?.id,
      align: "left",
      minWidth: 170,
      hidden: true,
    },
    {
      label: "Session Id",
      value: session?.id,
      align: "left",
      minWidth: 170,
      hidden: true,
    },
    {
      label: "Batch Id",
      value: batch?.id,
      align: "left",
      minWidth: 170,
      hidden: true,
    },
    {
      label: "Course Name",
      value: courseId
        ? course
          ? course?.data?.courseName
          : "Deleted"
        : null,
      align: "left",
      minWidth: 200,
    },
    {
      label: "Batch Name",
      value: batchId
        ? batch
          ? batch?.data?.batchName
          : "Deleted"
        : null,
      align: "left",
      minWidth: 170,
    },
    {
      label: "Session Name",
      value:
        session?.data?.startMonth && session?.data?.startYear
          ? `${session.data.startMonth} ${session.data.startYear}`
          : null,
      align: "left",
      minWidth: 170,
    },
    {
      label: "Slot Day",
      value: batch?.data?.slotDay,
      align: "left",
      minWidth: 200,
    },
    {
      label: "Slot Time",
      value: batch?.data?.slotTime,
      align: "left",
      minWidth: 170,
    },
    {
      label: "Primary Teacher (PT)",
      value: primaryTeacher ? primaryTeacher.data.name : null,
      align: "left",
      minWidth: 190,
    },
    {
      label: "PT Phone Number",
      value: primaryTeacher ? primaryTeacher.data.phoneNumber : null,
      align: "left",
      minWidth: 170,
    },
    {
      label: "PT City",
      value: primaryTeacher ? primaryTeacher.data.city : null,
      align: "left",
      minWidth: 150,
    },
    {
      label: "Secondary Teacher (ST)",
      value: secondaryTeacher ? secondaryTeacher.data.name : null,
      align: "left",
      minWidth: 210,
    },
    {
      label: "ST Phone Number",
      value: secondaryTeacher
        ? secondaryTeacher.data.phoneNumber
        : null,
      align: "left",
      minWidth: 170,
    },
    {
      label: "ST City",
      value: secondaryTeacher ? secondaryTeacher.data.city : null,
      align: "left",
      minWidth: 150,
    },
    {
      label: "Student Name",
      value: student?.data
        ? `${student.data.firstName} ${student.data.lastName}`
        : null,
      align: "left",
      minWidth: 170,
    },
    {
      label: "Gender",
      value: student?.data.gender,
      align: "left",
      minWidth: 170,
    },
    {
      label: "DOB",
      value: student?.data.dob
        ?.toDate()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/"),
      align: "left",
      minWidth: 170,
    },
    {
      label: "Phone Number",
      value: student?.data.mobileNumber,
      align: "left",
      minWidth: 170,
    },
    {
      label: "WhatsApp Number",
      value: student?.data.whatsAppMobileNumber
        ? student?.data.whatsAppMobileNumber
        : null,
      align: "left",
      minWidth: 170,
    },
    {
      label: "Email",
      value: student?.data.email,
      align: "left",
      minWidth: 170,
    },
    {
      label: "Address Line 1",
      value: student?.data.addressLine1,
      align: "left",
      minWidth: 170,
    },
    {
      label: "Address Line 2",
      value: student?.data.addressLine2,
      align: "left",
      minWidth: 170,
    },
    {
      label: "Landmark",
      value: student?.data.landmark,
      align: "left",
      minWidth: 170,
    },
    {
      label: "Town/Village",
      value: student?.data.townOrVillage,
      align: "left",
      minWidth: 170,
    },
    {
      label: "City",
      value: student?.data.city,
      align: "left",
      minWidth: 150,
    },
    {
      label: "State",
      value: student?.data.state,
      align: "left",
      minWidth: 170,
    },
    {
      label: "Country",
      value: student?.data.country,
      align: "left",
      minWidth: 150,
    },
    {
      label: "Pincode/Postcode",
      value: student?.data.pincode,
      align: "left",
      minWidth: 170,
    },
    {
      label: "Course Material Delivery",
      value: materialDeliveryPref ? "Yes" : "No",
      align: "left",
      minWidth: 220,
    },
    {
      label: "Registered On",
      value: timestamp
        ? timestamp.toDate().toLocaleString("en-US")
        : "Not Available",
      align: "left",
      minWidth: 220,
    },
  ];
}
