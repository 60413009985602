import { Box, Divider, Stack, Typography } from "@mui/material";
import { Email, MailLock } from "@mui/icons-material";

import Container from "../custom/container/Container";
import React from "react";
import donation_qr from "../../donation_qr2.png";

function Donation() {
  return (
    <Container
      heading="💖 Support Us"
      basic
      align="center"
      helperText="Our ATMA team humbly requests your valuable support in enhancing your spiritual learning experience."
    >
      <div>
        <Stack
          flexDirection={{ xs: "column", md: "row-reverse" }}
          alignItems="center"
          alignContent="center"
          alignSelf="center"
          marginTop="20px"
          gap={{ xs: "30px", md: "60px" }}
        >
          <Box>
            {/* QR Code */}
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="subtitle">Scan this QR</Typography>
            </Box>
            <Box
              component="img"
              src={donation_qr}
              alt="QR code"
              sx={{ width: { md: "180px", xs: "200px" }, height: "auto" }}
            />
          </Box>
          <Stack
            sx={{ display: { xs: "none", md: "flex" } }}
            alignItems="center"
          >
            <Divider orientation="vertical" sx={{ height: "100px" }} />
            <Typography variant="subtitle" sx={{ marginBlock: "10px" }}>
              OR
            </Typography>
            <Divider orientation="vertical" sx={{ height: "100px" }} />
          </Stack>
          <Stack
            sx={{ display: { xs: "flex", md: "none" } }}
            alignItems="center"
            flexDirection="row"
          >
            <Divider sx={{ width: "100px" }} />
            <Typography variant="subtitle" sx={{ marginInline: "10px" }}>
              OR
            </Typography>
            <Divider sx={{ width: "100px" }} />
          </Stack>
          <Box textAlign={{ md: "left", xs: "center" }}>
            {/* Bank Account Details */}
            <Box sx={{ marginBottom: { xs: "15px", md: "20px" } }}>
              <Typography variant="subtitle">
                Transfer to Bank Account
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle">Beneficiary</Typography>
              <Typography variant="h6">
                Shri Adinath Kund Kund Kahan Digamber Jain Trust, Aligarh
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle">Bank Name</Typography>
              <Typography variant="h6">Punjab National Bank</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle">Account Number</Typography>
              <Typography variant="h6">1825000100065332</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle">IFSC Code</Typography>
              <Typography variant="h6">PUNB0001000</Typography>
            </Box>
          </Box>
        </Stack>
      </div>
      <Box sx={{ marginTop: { xs: "20px", md: "30px" } }}>
        📧 Mail us at:
      </Box>
      <Box>
        <a
          href={`mailto:infomangalvidyapeeth@gmail.com?subject=${encodeURIComponent('Please help me! - ATMA Support Request (MVP)')}&cc:info@mangalayatan.com.com`}
          target="_blank"
          rel="noopener noreferrer"
          style={{color: "#0b80d8"}}
        >
          infomangalvidyapeeth@gmail.com
        </a>
      </Box>
      <Box>
        <a
          href={`mailto:info@mangalayatan.com.com?subject=${encodeURIComponent('Please help me! - Mangalayatan Support Request (MVP)')}&cc:infomangalvidyapeeth@gmail.com`}
          target="_blank"
          rel="noopener noreferrer"
          style={{color: "#0b80d8"}}
        >
          info@mangalayatan.com
        </a>
      </Box>
    </Container>
  );
}

export default Donation;
